.container {
  margin: 40px auto;
  padding-bottom: 40px;
  max-width: 1010px;
  width: 100%;
  position: relative;
  align-items: stretch;
  display: flex;
  gap: 20px;
}

.promo {
  width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    padding: 10px;
    background: linear-gradient(105.4deg, #f08223 6.72%, #ea592a 74.12%);
    border-radius: 12px;
    cursor: pointer;

    img {
      display: block;
      max-width: 180px;
      margin: 0 auto 12px;
    }

    h2 {
      text-align: center;
      color: var(--white);
      span {
        color: var(--black);
      }
    }

    &_glow {
      filter: drop-shadow(0px 10px 30px rgba(234, 89, 42, 0.5))
        drop-shadow(0px 0px 20px rgba(234, 89, 42, 0.6));
    }
  }
}

.articles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1050px) {
  .container {
    margin: 60px 20px 20px;
    width: auto;
  }
}

@media (max-width: 850px) {
  .container {
    flex-direction: column-reverse;
  }

  .promo {
    width: 100%;
  }
}
