.adv {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  background: linear-gradient(
    90.07deg,
    var(--light-orange) 7.74%,
    var(--orange) 98.68%
  );

  p {
    text-align: center;
    font-weight: bold;
    color: var(--black);
    font-size: 24px;
    line-height: 29px;
  }
}

.header {
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.burger{
  transition: all .3s;
  transform-origin: center center;
  position: relative;
  z-index: 3000000;

  &.active{
    transform: rotate(90deg);
  }
}

.left,
.right {
  display: flex;
  align-items: center;
  gap: 45px;
}

.header {
  width: 100%;
}

.social {
  display: flex;
  align-items: center;
  gap: 30px;
}

.mob{
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  gap: 15px;

  &__item {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--white);
    transition: color 0.3s;
    text-decoration: none;

    &:hover {
      color: var(--orange);
    }
  }
}

.basket {
  position: relative;
  text-decoration: none;

  &__total {
    font-size: 11px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--orange);
    padding: 3px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    top: -6px;
    right: -6px;
  }
}

.mob_menu{
  width: 100vw;
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 20px;
  gap: 20px;
  background: linear-gradient(105.4deg, #f08223 6.72%, #ea592a 74.12%);
  z-index: 1000;
  transition: all .7s;
  .social{
    display: flex;
  }

  &__active{
    transform: translateX(0%);
  }
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .adv {
    height: auto;
    min-height: 50px;

    p {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .social, .right{
    display: none;
  }

  .left a{
    max-width: 222px;

    svg{
      width: 100%;
    }
  }

  .mob{
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
