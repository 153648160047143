.container {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  padding: 105px 20px 120px;

  @media (max-width: 1000px) {
    padding: 60px 20px;
  }
}
