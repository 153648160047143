.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.symbol {
  font-weight: bold;
  padding: 10px;
  font-size: 24px;
  line-height: 24px;
  color: var(--black);
  cursor: pointer;
}
