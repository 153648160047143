@import "./colors.scss";

body {
  font-family: "Montserrat", sans-serif;
}

body,
html {
  background-color: var(--black);
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// background: linear-gradient(105.4deg, #F08223 6.72%, #EA592A 74.12%); для побочный страниц
