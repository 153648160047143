.container {
  margin: 40px auto;
  max-width: 700px;
  width: 100%;

  @media (max-width: 740px) {
    margin: 20px;
    width: auto;
  }
}

.name {
  font-size: 28px;
  font-weight: bold;
  color: var(--white);
  margin-top: 25px;
  margin-bottom: 15px;

  span {
    color: var(--orange);
  }
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background: linear-gradient(105.4deg, #f08223 6.72%, #ea592a 74.12%);
  width: 100%;

  &__row {
    gap: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .close {
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.total {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  margin-left: auto;

  span {
    color: var(--white);
    font-size: 24px;
    width: max-content;
    flex-shrink: 0;
  }

  &__button {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--white);
    outline: none;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    color: var(--orange);
    border: 1px solid var(--orange);
    border-radius: 6px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: var(--white);
      background: var(--orange);
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    &__button {
      width: 100%;
    }
  }
}

.color {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-right: auto;

  &[data-color="red"] {
    background: #6f2e2a;
  }

  &[data-color="blue"] {
    background: #6294b1;
  }

  &[data-color="green"] {
    background: #43432b;
  }
}
